"use client";

import { COLORS, ThemeRegistry } from "@ea/ui";
import { AppRouterCacheProvider } from "@mui/material-nextjs/v14-appRouter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NextTopLoader from "nextjs-toploader";
import React, { useState } from "react";
import { Toaster } from "react-hot-toast";

type ProvidersProps = {
  children: React.ReactNode;
};

export const Providers = ({ children }: ProvidersProps) => {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <AppRouterCacheProvider>
      <ThemeRegistry>
        <QueryClientProvider client={queryClient}>
          <NextTopLoader showSpinner={false} height={4} />
          <Toaster
            toastOptions={{
              style: {
                color: "white",
                backgroundColor: COLORS.NEUTRAL[900],
                borderRadius: "8px",
              },
            }}
            position="bottom-left"
          />
          {children}
        </QueryClientProvider>
      </ThemeRegistry>
    </AppRouterCacheProvider>
  );
};
